<template>
<v-card flat>
    <v-container>
        <v-row class="px-3">
            <v-col cols="12" class="py-0">
                <v-select :items="residencia" label="Residencia" outlined dense prepend-icon="mdi-home" item-text="nombre" item-value="value" v-model="datos_edit.residencia" :menu-props="{offsetY: true, rounded:'lg', transition:'slide-y-transition'}"></v-select>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-text-field label="Ingresa tu telefono" outlined dense prepend-icon="mdi-phone" v-model="datos_edit.telefono" :maxlength="9" autocomplete="off" type="number" prefix="+569" v-mask="'XXXXXXXX'"/>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-text-field label="Ingresa tu correo" outlined dense prepend-icon="mdi-email-outline" v-model="datos_edit.correo" autocomplete="off" type="email" />
            </v-col>
            <v-col cols="12" class="py-0 mb-3">
                <v-menu v-model="menu_tarjeta" :close-on-content-click="false" transition="scale-transition" min-width="auto" right rounded="lg">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Vencimiento tarjeta" outlined dense prepend-icon="mdi-calendar-range" v-model="DialogTarjetaFormat" maxlength="12" autocomplete="off" v-bind="attrs" v-on="on" readonly hide-details />
                    </template>
                    <v-date-picker v-model="datos_edit.tarjeta" @input="menu_tarjeta = false" scrollable flat :show-current="true" no-title first-day-of-week="1"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="5" offset="1" class="px-1 mb-3" v-if="datos_edit.hasOwnProperty('acceso')">
                <v-switch v-model="datos_edit.acceso" :label="datos_edit.acceso?'Con acceso':'Sin acceso'" hide-details class="mt-0" inset/>
            </v-col>
            <v-col cols="6" class="px-1 mb-3" v-if="datos_edit.hasOwnProperty('habilitado')">
                <v-switch v-model="datos_edit.habilitado" :label="datos_edit.habilitado?'Habilitado':'Inhabilidato'" hide-details class="mt-0" inset/>
            </v-col>
            <v-col cols="12" class="py-0 mb-3" v-if="datos_edit.hasOwnProperty('expiracion')">
                <v-menu v-model="menu_expiracion" :close-on-content-click="false" transition="scale-transition" min-width="auto" right rounded="lg">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field label="Expiracion acceso" outlined dense prepend-icon="mdi-lock-clock" v-model="DialogExpiracionFormat" maxlength="12" autocomplete="off" v-bind="attrs" v-on="on" readonly hide-details />
                    </template>
                    <v-date-picker v-model="datos_edit.expiracion" @input="menu_expiracion = false" scrollable flat :show-current="true" no-title first-day-of-week="1"></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col>
                <v-card class="mx-auto text-center" flat>
                    <v-btn depressed color="primary" @click="UpdateDatos()" width="150" :disabled="!ModificarBtn">Editar</v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</v-card>
</template>

<script>
import {
    mapState,
    mapMutations
} from 'vuex'
import dayjs from "dayjs";
export default {
    name: 'PerfilEdit',
    props: ['datos', 'vista', 'usuario_id'],
    data() {
        return {
            datos_edit: {},
            residencia: [
                { nombre: 'Sin Informacion', value: 0},
                { nombre: 'Valparaiso', value: 11},
                { nombre: 'Vina del Mar', value: 12},
                { nombre: 'Concon', value: 13 },
                { nombre: 'Casablanca', value: 14 },
                { nombre: 'Santiago', value: 15 },
                { nombre: 'Quilpue', value: 21 },
                { nombre: 'Villa Alemana', value: 22 },
                { nombre: 'Limache', value: 31 },
                { nombre: 'Olmue', value: 32 },
                { nombre: 'Quillota', value: 33 }
            ],
            menu_tarjeta: false,
            menu_expiracion: false
        }
    },
    computed: {
        ...mapState(['usuario', 'sistema', 'dialog', 'update']),
        DialogTarjetaFormat() {
            let fecha_tarjeta
            if (this.datos_edit.tarjeta) {
                fecha_tarjeta = dayjs(this.datos_edit.tarjeta).format('DD MMMM YYYY')
            }
            return fecha_tarjeta
        },
        DialogExpiracionFormat() {
            let fecha_expiracion
            if (this.datos_edit.expiracion) {
                fecha_expiracion = dayjs(this.datos_edit.expiracion).format('DD MMMM YYYY')
            }
            return fecha_expiracion
        },
        ModificarBtn() {
            let status = false
            if (this.datos_edit.correo) {
                status = true
            }
            return status
        }
    },
    methods: {
        ...mapMutations(['LogOut', 'ShowSnackBar', 'ErrorRequest', 'CloseDialog', 'UpdateComponente']),
        async UpdateDatos() {
            let datos = {
                correo: this.datos_edit.correo,
                residencia: this.datos_edit.residencia,
                contacto: this.datos_edit.telefono,
                tarjeta: this.datos_edit.tarjeta
            }
            if ('acceso' in this.datos_edit) {
                datos.acceso = this.datos_edit.acceso,
                    datos.habilitado = this.datos_edit.habilitado,
                    datos.expiracion = this.datos_edit.expiracion
            }
            if (this.usuario_id) datos.usuario_id = this.usuario_id
            await this.axios.post(`/perfil/edit`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (this.vista == 'perfil') {
                        this.CloseDialog('perfil_edit')
                        this.update.perfil = true
                    }
                    if (this.vista == 'buscar') {
                        this.CloseDialog('usuario')
                        this.UpdateComponente('buscar')
                    }

                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
    },
    watch: {
        datos: function (val) {
            if (val) this.datos_edit = JSON.parse(JSON.stringify(this.datos))
        }
    },
    created() {
        this.datos_edit = JSON.parse(JSON.stringify(this.datos))
    }

}
</script>

<style scoped>
.theme--dark.v-list{
    background-color: #424242 !important;
}

</style>
